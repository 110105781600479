import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = [
    'groundTimeInTransit',
    'overnightTimeInTransit',
    'second_dayTimeInTransit',
    'shippingOptions',
    'three_dayTimeInTransit'
  ]

  static values = {
    supplierId: Number
  }

  shippingOptionsTargetConnected(e) {
    document.addEventListener(`loadedTimeInTransit${this.supplierIdValue}`, (event) =>
      this.updateShippingTimeInTransit(event)
    )
  }

  shippingOptionsTargetDisconnected(e) {
    document.removeEventListener(`loadedTimeInTransit${this.supplierIdValue}`, (event) =>
      this.updateShippingTimeInTransit(event)
    )
  }

  updateShippingTimeInTransit(e) {
    const isWeekend = dayjs().day() === 6 || dayjs().day() === 0

    Object.values(e.detail.data).forEach((serviceData) => {
      const timeInTransit = serviceData
      const hasTimeInTransit =
        !isWeekend && timeInTransit && timeInTransit.length != 0
      if (hasTimeInTransit) {
        let targetName = `${serviceData.service_type}TimeInTransitTarget`
        let deliveryDate = `(${this.formatTimeInTransit(timeInTransit)})`
        this[targetName].innerHTML = deliveryDate
      }
    })
  }

  formatTimeInTransit = (timeInTransit) => {
    // Format the delivery date, displaying "tomorrow" or "today"
    // instead of the weekday if applicable.
    const deliveryDate = timeInTransit.delivery_date
    const today = dayjs().format('YYYY-MM-DD')
    const tomorrow = dayjs(today).add(1, 'days').format('YYYY-MM-DD')
    let deliveryDayDisplay = ''
    if (deliveryDate === today) {
      deliveryDayDisplay = I18n.t('global.today')
    } else if (deliveryDate === tomorrow) {
      deliveryDayDisplay = I18n.t('global.tomorrow')
    }

    let deliveryDateFormat = 'MMM D'
    let deliveryDateFormatted = dayjs(deliveryDate).format(deliveryDateFormat)
    if (deliveryDayDisplay.length > 0) {
      deliveryDateFormatted = deliveryDayDisplay
    }

    return deliveryDateFormatted
  }
}
