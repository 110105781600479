import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    page: String
  }
  static targets = ['selectDealsCrawlerFilter']

  connect() {
    this.dealTypes = ['deals', 'bundles', 'discounts']
    this.loopCount = this.dealTypes.length

    // set time of interval in seconds
    this.interval = 5 * 60 // 5 minutes
    // current index of dealTypes
    this.current = 0

    setTimeout(() => this.loadDealsBulletins(true), 500)
    this.intervalId = setInterval(() => {
      this.current = (this.current + 1) % this.loopCount
      this.loadDealsBulletins()
    }, this.interval * 1000)
  }

  resetInterval() {
    clearInterval(this.intervalId)
    this.intervalId = setInterval(() => {
      this.current = (this.current + 1) % this.loopCount
      this.loadDealsBulletins()
    }, this.interval * 1000)
  }

  loadDealsBulletins(initJs) {
    const dealType = this.dealTypes[this.current]
    this.selectDealsCrawlerFilterTarget.value = dealType
    $.ajax({
      type: 'GET',
      cache: false,
      dataType: 'script',
      url: '/pharmacy/deals_bulletins',
      headers: { Authorization: 'Web' },
      data: {
        page: this.pageValue,
        deal_type: dealType,
        init_js: initJs
      },
      beforeSend() {
        $('.dot-flashing-wrapper').show()
      },
      success(data) {
        $('.dot-flashing-wrapper').hide()
      },
      error(jqXHR, textStatus, errorThrown) {
        console.error(jqXHR.statusText)
      }
    })
  }

  loadDealsBulletinsByType() {
    this.current = this.dealTypes.indexOf(
      this.selectDealsCrawlerFilterTarget.value
    )
    this.loadDealsBulletins()
    this.resetInterval()
  }
}
