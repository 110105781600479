import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="pharmacy--market-place--aggregation-filters"
export default class extends Controller {
  static targets = [
    'productsList',
    'form',
    'strength',
    'manufacturer',
    'sellerName',
    'parentCategory',
    'childCategoryLevel1',
    'shortDate',
    'hideShortDate',
    'p2pOnly',
    'hideP2p',
    'hideNonNabp',
    'sortDir',
    'filtersList',
    'selectedP2pFilter',
    'selectedHideP2pFilter',
    'selectedHideNonNabp',
    'selectedShortDateFilter',
    'selectedHideShortDateFilter',
    'selectedCategory',
    'selectedForm',
    'selectedStrength',
    'selectedManufacturer',
    'selectedSellerName'
  ]

  static values = {
    valuesApiUrl: String,
    allCategoryData: Array
  }

  initialize() {
    this.handlePopstate = this.handlePopstate.bind(this)
    this.formLoaded = false
    this.optionSelected = ''
    this.strengthLoaded = false
    this.selectedForm = ''
    this.selectedStrength = ''
    this.selectedManufacturer = []
    this.selectedSellerName = []
    this.formData = new Map()
    this.strengthData = new Map()
    this.sellerNameData = new Map()
    this.manufacturerData = new Map()
    this.categoryData = {}

    this.keywords = ''
    this.ndc = ''
    this.ddi = ''
    this.gpi = ''
    this.category = ''
    this.p2pOnly = ''
    this.hideP2p = ''
    this.hideNonNabp =
      (Cookies.get('hide_non_vawd_accredited') ? 'vawd_only' : '') || ''
    // default sorting preferences
    this.sortBy = ''
    this.sortDir = ''
    this.showShortDate = ''
    this.hideShortDate = ''
    this.page = ''
  }

  connect() {
    this.initCategoryHeader()
    this.initFormAndStrengthFilters()
    this.loadFiltersFromURL()
    this.setupSortFilter()
    this.initStickyFilterAutocomplete()
    // Add event listener for popstate using arrow function
    window.addEventListener('popstate', this.handlePopstate)
  }

  disconnect() {
    // Remove event listener for popstate
    window.removeEventListener('popstate', this.handlePopstate)
    if (this.stickyFilterObserver) {
      const originalHeader = document.querySelector('#productFilter')
      if (!originalHeader) return
      this.stickyFilterObserver.unobserve(originalHeader)
    }
  }

  setupStickyFilter() {
    const stickyHeader = document.querySelector('#productStickyFilter')
    this.stickyFilterObserver = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
          stickyHeader.classList.add('visible')
          // hide main header dropdowns
          $('.categories-nav')
            .find('.category-item')
            .removeClass('open')
            .trigger('hide.bs.dropdown')
        } else {
          stickyHeader.classList.remove('visible')
          // hide sticky header dropdowns
          $(stickyHeader)
            .find('.category-item')
            .removeClass('open')
            .trigger('hide.bs.dropdown')
          $('#ndc-simple-search').autocomplete('close')
        }
      },
      { threshold: [0] }
    )

    const originalHeader = document.querySelector('#productFilter')
    this.stickyFilterObserver.observe(originalHeader)
  }

  setupSortFilter() {
    const _this = this
    const optionsObj = {
      lowest_price: I18n.t('global.price'),
      name: I18n.t('global.name'),
      asc: () =>
        _this.sortBy === 'name'
          ? I18n.t('global.asc_name')
          : I18n.t('global.asc_price'),
      desc: () =>
        _this.sortBy === 'name'
          ? I18n.t('global.desc_name')
          : I18n.t('global.desc_price')
    }
    $('#sortDirection').customSelect({
      selected: _this.sortDir || 'asc',
      enableSearch: false,
      clearable: false,
      label: (selected) => optionsObj[selected] || I18n.t('sorts.sort_by'),
      options: [
        { val: 'asc', title: optionsObj.asc() },
        { val: 'desc', title: optionsObj.desc() }
      ],
      onItemAdd: (value) => {
        _this.sortDir = value
        _this.sortBy = this.sortBy || 'lowest_price'
        _this.loadDefaultMarketplaceProducts()
      }
    })
    $('#sortBy').customSelect({
      selected: _this.sortBy || 'lowest_price',
      enableSearch: false,
      clearable: false,
      label: (selected) => optionsObj[selected] || I18n.t('sorts.sort_by'),
      options: [
        { val: 'lowest_price', title: optionsObj.lowest_price },
        { val: 'name', title: optionsObj.name }
      ],
      onItemAdd: (value) => {
        _this.sortBy = value
        _this.sortDir = this.sortDir || 'asc'
        $('#sortDirection')[0].customSelect.updateOptions([
          { val: 'asc', title: optionsObj.asc() },
          { val: 'desc', title: optionsObj.desc() }
        ])
        _this.loadDefaultMarketplaceProducts()
      }
    })
  }

  invertObject(obj) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [value, key])
    )
  }

  initStickyFilterAutocomplete() {
    if ($('.simple-search-form').length > 0) {
      ez_autocomplete({
        _selector: '#ndc-simple-search',
        _url: $('.simple-search-form').attr('search-url'),
        _limit: 10,
        _redirect: true,
        _search_by_name: true,
        _options: {
          appendTo: '#productStickyFilter .product-filter'
        }
      }).choice((data) => {})

      const $form = $('.simple-search-form')
      const $searchInput = $('#ndc-simple-search')
      const $closeBtn = $form.find('#close-btn')

      $searchInput.keyup(function () {
        $closeBtn.toggle($(this).val().trim().length > 0)
      })

      $closeBtn.on('click', function (e) {
        $searchInput.val('')
        $(this).hide()
        $searchInput.focus()
      })

      if ($searchInput.val()) {
        $closeBtn.show()
      } else {
        $closeBtn.hide()
      }
    }
  }

  initCategoryHeader() {
    const [parentCategoriesObj, childCategoriesObj] = this.allCategoryDataValue
    this.categoryData = {
      parent: this.invertObject(parentCategoriesObj),
      child: this.invertObject(childCategoriesObj)
    }

    // close cateogry dropdown on item click
    $('a.dropdown-item').on('click', function (e) {
      $(this)
        .closest('li.category-item')
        .removeClass('open')
        .trigger('hide.bs.dropdown')
    })

    // slidedown category dropdown on trigger
    $('.category-item').on({
      'shown.bs.dropdown': function () {
        $(this).find('ul').slideDown({ duration: 300 })
        $.addTitleIfTruncated($(this).find('a.dropdown-item'))
      },
      'hide.bs.dropdown': function () {
        $(this).find('ul').slideUp({ duration: 100 })
      }
    })
  }

  nextPage(event) {
    let fromPagination = true
    this.page = event.params.nextPage
    this.loadDefaultMarketplaceProducts(false, fromPagination)
  }

  previousPage(event) {
    let fromPagination = true
    this.page = event.params.previousPage
    this.loadDefaultMarketplaceProducts(false, fromPagination)
  }

  removeSelectedCategory(event) {
    this.selectedCategoryTarget.classList.add('d-none')
    this.category = ''
    this.loadDefaultMarketplaceProducts()
    this.childCategoryLevel1Targets.forEach(function (pTarget) {
      pTarget.parentElement.classList.remove('active')
    })
  }

  selectChildCategory(event) {
    this.category = event.params.childCategoryLevel1Value
    this.loadDefaultMarketplaceProducts()

    if (!event.currentTarget.parentElement.classList.contains('active')) {
      const categories = this.category.split(',')
      const formatCategoryText = `${
        this.categoryData.parent[categories[0]]
      } > ${this.categoryData.child[categories[1]]}`

      this.selectedCategoryTarget.innerHTML = `${formatCategoryText} <i class='icon-close-small small margin-left-5' ></i>`
    }

    this.parentCategoryTargets.forEach(function (pTarget) {
      pTarget.parentElement.classList.remove('active')
      if (
        pTarget.dataset.parentCategoryVal ===
        event.params.childCategoryLevel1Value.split(',')[0]
      ) {
        pTarget.parentElement.classList.add('active')
      }
    })
  }

  p2pFilter(event) {
    if (event.target.checked) {
      this.p2pOnly = 'p2p_only'
      this.hideP2p = ''
    } else {
      this.p2pOnly = ''

      // remove p2p_only param from main pharmacy search form
      $('#pharmacy_inventory_search_form input[name="p2p_only"]').remove()
    }
    if (this.hasHideP2pTarget) {
      this.hideP2pTarget.checked = false
    }
    this.loadDefaultMarketplaceProducts()
  }

  hideP2pFilter(event) {
    if (event.target.checked) {
      this.hideP2p = 'hide_p2p'
      this.p2pOnly = ''
    } else {
      this.hideP2p = ''
    }
    if (this.hasP2pOnlyTarget) {
      this.p2pOnlyTarget.checked = false
    }
    this.loadDefaultMarketplaceProducts()
  }

  shortDateFilter(event) {
    if (event.target.checked) {
      this.showShortDate = 'short_date'
      this.hideShortDate = ''
    } else {
      this.showShortDate = ''
    }
    if (this.hasHideShortDateTarget) {
      this.hideShortDateTarget.checked = false
    }
    this.loadDefaultMarketplaceProducts()
  }

  hideShortDateFilter(event) {
    if (event.target.checked) {
      this.hideShortDate = 'hide_short_date'
      this.showShortDate = ''
    } else {
      this.hideShortDate = ''
    }

    if (this.hasShortDateTarget) {
      this.shortDateTarget.checked = false
    }
    this.loadDefaultMarketplaceProducts()
  }

  hideNonNabpFilter(event) {
    if (event.target.checked) {
      this.hideNonNabp = 'vawd_only'
      Cookies.set('hide_non_vawd_accredited', 'true', { expires: 1000 })
    } else {
      this.hideNonNabp = ''
      Cookies.remove('hide_non_vawd_accredited')
    }
    this.loadDefaultMarketplaceProducts()
  }

  selectSortDir(value) {
    this.sortDir = value
    this.sortBy = this.sortBy || 'lowest_price'
    this.loadDefaultMarketplaceProducts()
  }

  selectSortBy(value) {
    this.sortBy = value
    this.sortDir = this.sortDir || 'asc'

    if (this.sortBy === 'lowest_price') {
      this.sortDirAscTarget.text = this.sortDirAscTarget.dataset.priceText
      this.sortDirDescTarget.text = this.sortDirDescTarget.dataset.priceText
    } else if (this.sortBy === 'name') {
      this.sortDirAscTarget.text =
        this.sortDirAscTarget.dataset.alphabeticalText
      this.sortDirDescTarget.text =
        this.sortDirDescTarget.dataset.alphabeticalText
    }
    this.loadDefaultMarketplaceProducts()
  }

  handlePopstate() {
    this.loadFiltersFromURL()
    const params = this.returnBrowserParams()
    const url = this.returnBrowserUrl(params)
    Turbo.visit(url)
  }

  loadFiltersFromURL() {
    const urlParams = new URLSearchParams(window.location.search)
    this.page = urlParams.get('page') || ''
    this.optionSelected = urlParams.get('selected') || ''
    this.selectedForm = urlParams.get('form') || ''
    this.selectedStrength = urlParams.get('dosage') || ''
    this.selectedManufacturer = urlParams.get('manufacturer')
      ? urlParams.get('manufacturer').split(';')
      : []
    this.selectedSellerName = urlParams.get('seller_name')
      ? urlParams.get('seller_name').split(';')
      : []
    this.keywords = urlParams.get('keywords') || ''
    this.ndc = urlParams.get('ndc') || ''
    this.ddi = urlParams.get('ddi') || ''
    this.gpi = urlParams.get('gpi') || ''
    this.category = urlParams.get('category') || ''
    this.sortDir = urlParams.get('sort_dir') || this.sortDir
    this.sortBy = urlParams.get('sort_by') || this.sortBy
    this.p2pOnly = urlParams.get('p2p_only') || ''
    this.hideP2p = urlParams.get('hide_p2p') || ''
    this.hideNonNabp =
      urlParams.get('vawd_only') || Cookies.get('hide_non_vawd_accredited')
        ? 'vawd_only'
        : ''
    this.showShortDate = urlParams.get('short_date') || ''
    this.hideShortDate = urlParams.get('hide_short_date') || ''
  }

  returnBrowserUrl(params) {
    return `/products?${params.toString() ? `${params.toString()}` : ''}`
  }

  returnBrowserParams() {
    const form = this.selectedForm
    const strength = this.selectedStrength
    const keywords = this.keywords
    const gpi = this.gpi
    const ddi = this.ddi

    const params = new URLSearchParams()
    if (this.page) params.set('page', this.page)
    if (this.optionSelected) params.set('selected', this.optionSelected)
    if (form) params.set('form', form)
    if (strength) params.set('dosage', strength)
    if (keywords) params.set('keywords', keywords)
    if (this.ndc) params.set('ndc', this.ndc)
    if (gpi) params.set('gpi', gpi)
    if (ddi) params.set('ddi', ddi)
    if (this.selectedManufacturer.length)
      params.set('manufacturer', this.selectedManufacturer.join(';'))
    if (this.selectedSellerName.length)
      params.set('seller_name', this.selectedSellerName.join(';'))
    if (this.category) params.set('category', this.category)
    if (this.sortBy) params.set('sort_by', this.sortBy)
    if (this.sortDir) params.set('sort_dir', this.sortDir)
    if (this.p2pOnly) params.set('p2p_only', this.p2pOnly)
    if (this.hideP2p) params.set('hide_p2p', this.hideP2p)
    if (this.hideNonNabp) params.set('vawd_only', this.hideNonNabp)
    if (this.showShortDate) params.set('short_date', this.showShortDate)
    if (this.hideShortDate) params.set('hide_short_date', this.hideShortDate)
    // default search type params
    params.set('search_type', 'gpi_products')
    return params
  }

  removeSelectedForm() {
    this.formTarget.customSelect.onClear()
  }

  removeSelectedStrength() {
    this.strengthTarget.customSelect.onClear()
  }

  removeSelectedManufacturer() {
    this.manufacturerTarget.customSelect.onClear()
  }

  removeSelectedSellerName(event) {
    this.sellerNameTarget.customSelect.onClear()
  }

  removeGpiDdiFilter() {
    this.gpi = ''
    this.ddi = ''
    this.optionSelected = ''
  }

  removeSearchedKeywords(event) {
    this.keywords = ''
    event.currentTarget.classList.add('d-none')
    if (!this.ndc) {
      this.removeGpiDdiFilter()
    }
    this.loadDefaultMarketplaceProducts()
  }

  removeSearchedNdc(event) {
    this.ndc = ''
    event.currentTarget.classList.add('d-none')
    if (!this.keywords) {
      this.removeGpiDdiFilter()
    }
    this.loadDefaultMarketplaceProducts()
  }

  clearFilter() {
    Turbo.visit('/products')
  }

  updateSelectedDropdownField({
    selector,
    selectedValue,
    target,
    data,
    itemName,
    defaultLabel
  }) {
    if (!selectedValue.length) return
    const suffix = selectedValue.length > 1 ? 's' : ''
    const text = `${selectedValue.length} ${itemName}${suffix} selected`
    target.innerHTML = text.concat(
      " <i class='icon-close-small small margin-left-5'></i>"
    )

    const $target = $(target)

    const tooltipText = selectedValue
      .map((v) => `<li>${data.get(v)}</li>`)
      .join('')
    $target.attr('data-original-title', tooltipText)
    $target.tooltip({
      placement: 'bottom',
      html: true
    })
  }

  showHideSelectedFilters() {
    if (!this.formLoaded) return
    this.selectedP2pFilterTarget.classList.toggle('d-none', !this.p2pOnly)
    this.selectedHideP2pFilterTarget.classList.toggle('d-none', !this.hideP2p)

    this.selectedShortDateFilterTarget.classList.toggle(
      'd-none',
      !this.showShortDate
    )

    this.selectedHideShortDateFilterTarget.classList.toggle(
      'd-none',
      !this.hideShortDate
    )

    this.selectedHideNonNabpTarget.classList.toggle('d-none', !this.hideNonNabp)

    if (this.category) {
      const _this = this
      const categories = this.category.split(',')
      const formatCategoryText = `${
        this.categoryData.parent[categories[0]]
      } > ${this.categoryData.child[categories[1]]}`
      this.selectedCategoryTarget.innerHTML = `${formatCategoryText} <i class='icon-close-small small margin-left-5'></i>`

      this.childCategoryLevel1Targets.forEach(function (pTarget) {
        pTarget.parentElement.classList.remove('active')
        if (pTarget.dataset.childCategoryLevel1Val === _this.category) {
          pTarget.parentElement.classList.add('active')
        }
      })
    }

    this.selectedCategoryTarget.classList.toggle('d-none', !this.category)

    if (this.selectedForm) {
      this.selectedFormTarget.innerHTML = `${this.formData.get(
        this.selectedForm
      )} <i class='icon-close-small small margin-left-5'></i>`
    }
    this.selectedFormTarget.classList.toggle('d-none', !this.selectedForm)

    if (this.selectedStrength) {
      this.selectedStrengthTarget.innerHTML = `${this.strengthData.get(
        this.selectedStrength
      )} <i class='icon-close-small small margin-left-5'></i>`
    }
    this.selectedStrengthTarget.classList.toggle(
      'd-none',
      !this.selectedStrength
    )

    this.updateSelectedDropdownField({
      selector: '#manufacturer',
      selectedValue: this.selectedManufacturer,
      target: this.selectedManufacturerTarget,
      data: this.manufacturerData,
      itemName: 'manufacturer',
      defaultLabel: I18n.t('global.manufacturer')
    })
    this.selectedManufacturerTarget.classList.toggle(
      'd-none',
      !this.selectedManufacturer.length
    )

    this.updateSelectedDropdownField({
      selector: '#seller_name',
      selectedValue: this.selectedSellerName,
      target: this.selectedSellerNameTarget,
      data: this.sellerNameData,
      itemName: 'seller',
      defaultLabel: I18n.t('global.seller_name')
    })
    this.selectedSellerNameTarget.classList.toggle(
      'd-none',
      !this.selectedSellerName.length
    )
  }

  loadDefaultMarketplaceProducts(initialLoad = false, fromPagination = false) {
    const params = this.returnBrowserParams()
    const browserUrl = this.returnBrowserUrl(params)
    const apiUrl = `/api/v1/marketplace/products.html?${
      params.toString() ? `${params.toString()}` : ''
    }`

    if (!initialLoad) {
      history.pushState(history.state, '', browserUrl)
    }
    this.showHideSelectedFilters()
    this.updateStickyFilter()
    const resultTarget = this.productsListTarget
    $.ajax({
      url: apiUrl,
      type: 'GET',
      cache: false,
      dataType: 'html',
      headers: {
        Authorization: 'Web'
      },
      data: {},
      error: function (e) {
        resultTarget.innerHTML = 'Something went wrong'
        console.log(e)
      },
      success: function (html) {
        resultTarget.innerHTML = ''
        resultTarget.insertAdjacentHTML('afterbegin', html)
        if (fromPagination) {
          resultTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          })
        }
      }
    })
  }

  formatApiData(data = []) {
    return data.map(function (x) {
      return {
        val: x[0],
        title: x[1]
      }
    })
  }

  formatAndFilterMultipleData(existingValues, valueToBeFiltered) {
    const existingData = existingValues.split(';')
    const filteredData = existingData.filter(
      (item) => item !== valueToBeFiltered
    )
    return filteredData.join(';')
  }

  saveData(res) {
    const idToString = (item) => [item[0].toString(), item[1].toString()]
    this.formData = new Map(res.form)
    this.strengthData = new Map(res.strength)
    this.manufacturerData = new Map(res.manufacturer_names.map(idToString))
    this.sellerNameData = new Map(res.seller_names.map(idToString))
  }

  initSingleDropdown(
    selector,
    data,
    selectedValue,
    valueData,
    label,
    defaultLabel
  ) {
    const options = this.formatApiData(data)

    const _this = this
    $(selector).customSelect({
      selected: _this[selectedValue] || '',
      options: options,
      placeholder: `Select ${label}...`,
      label: (selectedOption) => {
        return selectedOption
          ? _this[valueData].get(selectedOption)
          : defaultLabel
      },
      onItemAdd: (value) => {
        _this[selectedValue] = value
        _this.loadDefaultMarketplaceProducts()
      },
      onClear: () => {
        _this[selectedValue] = ''
        _this.loadDefaultMarketplaceProducts()
      }
    })
  }

  initCheckboxDropdown(selector, data, selectedValues, label, defaultLabel) {
    const options = this.formatApiData(data)
    const _this = this

    $(selector).customSelect({
      selected: _this[selectedValues] || [],
      options: options,
      placeholder: `Select ${label}...`,
      multiple: true,
      label: (selectedOptions = []) => {
        const suffix = selectedOptions.length > 1 ? 's' : ''
        return selectedOptions.length
          ? `${selectedOptions.length} ${label}${suffix} selected`
          : defaultLabel
      },
      onItemAdd: (value) => {
        _this[selectedValues].push(value)
        _this.loadDefaultMarketplaceProducts()
      },
      onItemRemove: (value) => {
        _this[selectedValues] = _this[selectedValues].filter(
          (item) => item !== value
        )
        _this.loadDefaultMarketplaceProducts()
      },
      onClear: () => {
        _this[selectedValues] = []
        _this.loadDefaultMarketplaceProducts()
      }
    })
  }

  initFormAndStrengthFilters(selector = '') {
    const url = '/api/v1/products/filters/form_and_strength'
    const stmThis = this
    $.ajax({
      url: url,
      type: 'GET',
      cache: false,
      dataType: 'json',
      headers: {
        Authorization: 'Web'
      },
      data: {},
      error: function () {
        console.log('error')
      },
      success: function (res) {
        stmThis.formLoaded = true
        stmThis.saveData(res)

        stmThis.initSingleDropdown(
          '#form',
          res.form,
          'selectedForm',
          'formData',
          'form',
          I18n.t('global.form')
        )
        stmThis.initSingleDropdown(
          '#strength',
          res.strength,
          'selectedStrength',
          'strengthData',
          'strength',
          I18n.t('global.strength')
        )

        stmThis.initCheckboxDropdown(
          '#seller_name',
          res.seller_names,
          'selectedSellerName',
          'seller',
          I18n.t('global.seller_name')
        )
        stmThis.initCheckboxDropdown(
          '#manufacturer',
          res.manufacturer_names,
          'selectedManufacturer',
          'manufacturer',
          I18n.t('global.manufacturer')
        )

        $('#productFilter .loading').addClass('hidden')
        $('#productFilter').removeClass('hidden')
        $('#productFilterTags').removeClass('hidden')

        stmThis.setupStickyFilter()
        stmThis.updateStickyFilter()
        stmThis.loadDefaultMarketplaceProducts(true)
      }
    })
  }

  updateStickyFilterSelect(selector, value) {
    const $field = $(`#stickyFilter${selector}`)
    if (!value) {
      $field.addClass('hidden')
      return
    }
    $field.removeClass('hidden')
    $field.find('.value').text(value)
    $field.attr('data-original-title', value)
    $field.tooltip({ placement: 'bottom' })
  }

  updateStickyFilterMultiSelect(selector, value, data) {
    const $field = $(`#stickyFilter${selector}`)
    if (!value.length) {
      $field.addClass('hidden')
      return
    }

    $field.removeClass('hidden')
    const $value = $field.find('.value')
    if (value.length === 1) {
      $value.text(data.get(value[0]))
      $value.addClass('single-value')
    } else {
      $value.removeClass('single-value')
      $value.text(`${value.length} ${selector.toLowerCase()}s selected`)
    }

    const tooltipText = value.map((v) => `<li>${data.get(v)}</li>`).join('')
    $field.attr('data-original-title', tooltipText)
    $field.tooltip({ placement: 'bottom', html: true })
  }

  updateStickyFilterCheckbox(selector, value) {
    const $field = $(`#stickyFilter${selector}`)
    if (!value) {
      $field.addClass('hidden')
      return
    }
    $field.removeClass('hidden')
  }

  updateStickyFilterSort(selector, sortBy, sortDir) {
    const $field = $(`#stickyFilter${selector}`)
    const $value = $field.find('.value')
    if (!sortBy) {
      $value.text(I18n.t('global.asc_price'))
      return
    }
    if (sortBy === 'lowest_price') {
      if (sortDir === 'asc') {
        $value.text(I18n.t('global.asc_price'))
      } else {
        $value.text(I18n.t('global.desc_price'))
      }
    } else {
      if (sortDir === 'asc') {
        $value.text(I18n.t('global.asc_name'))
      } else {
        $value.text(I18n.t('global.desc_name'))
      }
    }
  }

  updateStickyFilterCategory() {
    const $field = $('#stickyFilterCategory')
    const $subField = $('#stickyFilterSubCategory')
    if (!this.category) {
      $field.addClass('hidden')
      $subField.addClass('hidden')
      return
    }
    $field.removeClass('hidden')
    $subField.removeClass('hidden')

    const categories = this.category.split(',')
    const text = this.categoryData.parent[categories[0]]
    $field.find('.value').text(text)
    $field.attr('data-original-title', text)
    $field.tooltip({ placement: 'bottom' })

    const subText = this.categoryData.child[categories[1]]
    $subField.find('.value').text(subText)
    $subField.attr('data-original-title', subText)
    $subField.tooltip({ placement: 'bottom' })
  }

  updateStickyFilter() {
    if (!this.stickyFilterObserver) {
      return
    }
    this.updateStickyFilterSelect('Keyword', this.keywords)
    this.updateStickyFilterCategory()
    this.updateStickyFilterSelect('Form', this.formData.get(this.selectedForm))
    this.updateStickyFilterSelect(
      'Strength',
      this.strengthData.get(this.selectedStrength)
    )
    this.updateStickyFilterMultiSelect(
      'Seller',
      this.selectedSellerName,
      this.sellerNameData
    )
    this.updateStickyFilterMultiSelect(
      'Manufacturer',
      this.selectedManufacturer,
      this.manufacturerData
    )

    this.updateStickyFilterCheckbox('P2P', this.p2pOnly)
    this.updateStickyFilterCheckbox('HideP2P', this.hideP2p)
    this.updateStickyFilterCheckbox('ShortDate', this.showShortDate)
    this.updateStickyFilterCheckbox('HideShortDate', this.hideShortDate)
    this.updateStickyFilterCheckbox('HideNonNabp', this.hideNonNabp)
    this.updateStickyFilterSort('Sort', this.sortBy, this.sortDir)

    const hasAnyFilter = Boolean(
      this.keywords ||
        this.category ||
        this.selectedForm ||
        this.selectedStrength ||
        this.selectedSellerName.length ||
        this.selectedManufacturer.length ||
        this.p2pOnly ||
        this.hideP2p ||
        this.hideNonNabp ||
        this.showShortDate ||
        this.hideShortDate
    )

    $('.product-filter__default').toggleClass('hidden', hasAnyFilter)
    $('.product-filter__search').toggleClass('hidden', !hasAnyFilter)

    $('.item__search-label').toggleClass('hidden', !this.keywords)

    if (
      !this.category &&
      !this.selectedForm &&
      !this.selectedStrength &&
      !this.selectedSellerName.length &&
      !this.selectedManufacturer.length &&
      !this.p2pOnly &&
      !this.hideP2p &&
      !this.showShortDate &&
      !this.hideShortDate &&
      !this.hideNonNabp
    ) {
      $('.item__filter-label').addClass('hidden')
    } else {
      $('.item__filter-label').removeClass('hidden')
    }

    $('.item').removeClass('no-border')
    $('.item:not(.hidden)').last().addClass('no-border')
  }
}
