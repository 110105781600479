import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="admin--subpoena-reports--ndcs"
export default class extends Controller {
  static targets = [
    'importProductsForm',
    'importProductsFile',
    'importProducts',
    'ndc',
    'productListingTable',
    'productId',
  ]

  ndcTargetConnected(element) {
    this.bindAutoComplete(element)
  }

  productIdTargetConnected() {
    this.refreshProductListing()
  }

  removeFromReport(e) {
    e.target.parentElement.parentElement.parentElement.remove()
    this.refreshProductListing()
  }

  handleProductsImport(e) {
    e.preventDefault()

    if (this.importProductsFileTarget.files.length) {
      this.importProductsTarget.disabled = true
      const formData = new FormData();
      formData.append('file', this.importProductsFileTarget.files[0]);

      $.ajax({
        type: 'POST',
        cache: false,
        processData: false,
        contentType: false,
        dataType: 'script',
        url: '/admin/subpoena_reports/import_products',
        data: formData,
        headers: {
          Authorization: 'Web'
        },
      })
    }
  }

  refreshProductListing() {
    if (this.productIdTargets.length == 0) {
      this.productListingTableTarget.classList.add('hide')
    } else {
      this.productListingTableTarget.classList.remove('hide')
    }
  }

  bindAutoComplete(element) {
    const stimulusThis = this

    $(element).autocomplete({
      open: function () {
        setTimeout(function () {
          $('.ui-autocomplete').css('z-index', 1051)
        }, 0)
      },
      source: function (request, response) {
        $.ajax({
          type: 'GET',
          cache: false,
          dataType: 'json',
          url: '/api/v1/products/request/autosuggest',
          data: {
            keyword: $(element).val()
          },
          headers: {
            Authorization: 'Web'
          },
          beforeSend() {
            $('.ndc-search-loading').show()
          },
          success(data) {
            $('.ndc-search-loading').hide()

            response(
              $.map(data, function (item) {
                return {
                  label: `${item.ndc} - ${item.name} - ${item.package_size}`,
                  value: '',
                  item_id: item.id,
                  ndc: item.ndc,
                  form: item.form,
                  gpi: item.gpi,
                  ddi: item.ddi,
                  strength: item.strength,
                  package_qty: item.package_qty,
                  package_size: item.package_size,
                  manufacturer: item.manufacturer
                }
              })
            )
          },
          error(jqXHR, textStatus, errorThrown) {
            console.error('something went wrong')
            $('.ndc-search-loading').hide()
          }
        })
      },
      select: function (event, ui) {
        const productIds = stimulusThis.productIdTargets.map((t) =>
          Number(t.value)
        )

        if (productIds.includes(ui.item.item_id)) {
          setTimeout(function () {
            $('.ndc-input').val('')
          }, 100)
          alertify.error(I18n.t('reports.subpoena.product_already_added'))
        } else {
          $.get(
            `/admin/subpoena_reports/${ui.item.item_id}/add_product`
          )
        }
      },
      minLength: 3
    })
  }
}
