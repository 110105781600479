import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'sortByWacAwpPrice',
    'sortDirectionForWacAwpPrice'
  ]

  handleSort() {
    let currentUrl = window.location.href;
    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');

    // Parse the query string part of the URL
    let url = new URL(currentUrl);
    let searchParams = url.searchParams;
    const selectedSortOption = this.sortByWacAwpPriceTarget.value
    const selectedSortDir = this.sortDirectionForWacAwpPriceTarget.value || 'asc'

    if (selectedSortOption.length === 0) {
      Turbo.visit(decodeURIComponent(`${url}`))
      return false
    }

    if (searchParams.size > 0) {
      if (searchParams.has('q[s]')) {
        searchParams.set('q[s]', `${selectedSortOption} ${selectedSortDir}`);
      } else {
        searchParams.append('q[s]', `${selectedSortOption} ${selectedSortDir}`);
      }
    } else {
      searchParams.append('q[s]', `${selectedSortOption} ${selectedSortDir}`);
    }

    url.search = searchParams.toString();
    Turbo.visit(decodeURIComponent(`${url}`))
  }
}