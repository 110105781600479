import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pharmacy--questionnaire"
export default class extends Controller {
  static targets = [
    'questionnaireModal',
    'questionnaireForm',
    'brandsPurchased',
    'genericsPurchased',
    'brandsDiscount',
    'totalPurchasedRebate',
    'ndc',
    'productListingTable',
    'productId',
    'price',
    'addProduct',
    'sendAnswers',
    'neverShowAgain'
  ]

  static values = {
    filledByPharmacy: Boolean,
    hideQuestionnaire: Boolean,
    numberOfProductsRequired: Number,
    userId: Number,
    products: Array
  }

  connect() {
    if (!this.hideQuestionnaireValue) {
      $('#showQuestionnaire').modal('show')
    }
  }

  priceTargetConnected() {
    this.refreshProductListing()
  }

  priceTargetDisconnected() {
    this.refreshProductListing()
  }

  ndcTargetConnected(element) {
    this.bindAutoComplete(element)
  }

  validForm() {
    return (
      this.brandsPurchasedTarget.value &&
      this.genericsPurchasedTarget.value &&
      this.brandsDiscountTarget.value &&
      this.totalPurchasedRebateTarget.value &&
      this.priceTargets.length >= this.numberOfProductsRequiredValue
    )
  }

  valuesChanged() {
    if (this.filledByPharmacyValue) {
      if (this.validForm()) {
        this.sendAnswersTarget.innerHTML = I18n.t('questionnaire.send_answers')
      } else {
        this.sendAnswersTarget.innerHTML = I18n.t('questionnaire.fill_later')
      }
    } else {
      this.sendAnswersTarget.innerHTML = I18n.t('global.update')
    }
  }

  removeFromQuestionnaire(e) {
    e.target.parentElement.parentElement.parentElement.remove()
    this.refreshProductListing()
  }

  handleAddProduct(e) {
    e.preventDefault()

    this.ndcTarget.focus()
  }

  handleSubmit(e) {
    const stimulusThis = this
    let valid = $(this.questionnaireFormTarget).valid()

    if (valid) {
      this.sendAnswersTarget.disabled = true

      $.ajax({
        type: 'PUT',
        cache: false,
        url: this.filledByPharmacyValue
          ? '/api/v1/users/questionnaire'
          : `/admin/questionnaire/${this.userIdValue}}`,
        data: $(this.questionnaireFormTarget).serializeArray(),
        headers: {
          Authorization: 'Web'
        },
        complete: function (jqXHR, textStatus) {
          window.alertify.success(jqXHR.responseJSON.message)
          stimulusThis.sendAnswersTarget.disabled = false
          $('#showQuestionnaire').modal('hide')
          Turbo.visit('/')
        }
      })
    }
  }

  handleNeverShowAgain(e) {
    const stimulusThis = this
    this.neverShowAgainTarget.disabled = true

    $.ajax({
      type: 'PUT',
      cache: false,
      url: '/api/v1/users/questionnaire',
      data: {
        never_show_again: true
      },
      headers: {
        Authorization: 'Web'
      },
      complete: function (jqXHR, textStatus) {
        stimulusThis.neverShowAgainTarget.disabled = false
        $('#showQuestionnaire').modal('hide')
        Turbo.visit('/')
      }
    })
  }

  refreshProductListing() {
    if (this.priceTargets.length == 0) {
      this.productListingTableTarget.classList.add('hide')
      this.addProductTarget.innerHTML = I18n.t('questionnaire.add_product')
    } else {
      this.productListingTableTarget.classList.remove('hide')
      this.valuesChanged()

      if (this.priceTargets.length < this.numberOfProductsRequiredValue) {
        this.addProductTarget.innerHTML = `${I18n.t(
          'questionnaire.next_product'
        )} ${this.priceTargets.length}/${this.numberOfProductsRequiredValue}`
        this.addProductTarget.classList.remove('hide')
      } else {
        this.addProductTarget.classList.add('hide')
      }
    }
  }

  bindAutoComplete(element) {
    const stimulusThis = this

    $(element)
      .autocomplete({
        open: function () {
          setTimeout(function () {
            $('.ui-autocomplete').css('z-index', 1051)
          }, 0)
        },
        source: function (request, response) {
          const productIds = stimulusThis.productIdTargets.map((t) =>
            Number(t.value)
          )
          let products = $.map(stimulusThis.productsValue, function (product) {
            return {
              label: `${product.ndc_542} - ${product.display_name}`,
              value: '',
              id: product.id
            }
          })
          products = products.filter(function (product) {
            return !productIds.includes(product.id)
          })

          response($.ui.autocomplete.filter(products, request.term))
        },
        select: function (event, ui) {
          stimulusThis.ndcTarget.blur()
          const productIds = stimulusThis.productIdTargets.map((t) =>
            Number(t.value)
          )

          if (productIds.includes(ui.item.id)) {
            setTimeout(function () {
              $('.ndc-input').val('')
            }, 100)

            alertify.error(I18n.t('questionnaire.product_already_added'))
          } else {
            const url = stimulusThis.filledByPharmacyValue
              ? `/add_product_to_questionnaire?id=${ui.item.id}`
              : `/admin/questionnaire/${stimulusThis.userIdValue}/add_product?product_id=${ui.item.id}`
            $.get(url)
          }
        },
        minLength: 0
      })
      .focus(function () {
        $(element).autocomplete('search')
      })
  }
}
